import { GoArrowLeft } from "react-icons/go";
import { FiUploadCloud } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../context/firebase";
import { URI } from "../URL";

function AddProduct() {
  const navigate = useNavigate();
  const thumbnailImg = useRef();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [form, setForm] = useState({
    name: "",
    description: "",
    price: null,
    comission: null,
    comissionWithTextReview: null,
    comissionWithPictureReview:null,
    comissionWithVideoReview:null,

    guidelines: [],

    marketerInstructions: [],

    commissionPolicies: [],
  });

  const handleDeleteItem = (type, index) => {
    const updatedItems = form[type].filter((_, i) => i !== index);
    setForm({ ...form, [type]: updatedItems });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleKeyDown = (e, fieldName, fieldList) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmedValue = form[fieldName].trim();
      if (trimmedValue) {
        setForm({
          ...form,
          [fieldName]: "",
          [fieldList]: [...form[fieldList], trimmedValue],
        });
      }
    }
  };


  const addProduct = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a thumbnail image!");
      return;
    }

    setLoading(true);

    try {
      const imageRef = ref(storage, `images/${file.name + Date.now()}`);
      const uploadTask = await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(uploadTask.ref);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${URI}/api/products`,
        { ...form, image: downloadUrl },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Product created successfully!");
        setForm({
          name: "",
          description: "",
          price: null,
          comission: null,
          comissionWithTextReview: null,
          guideline: "",
          guidelines: [],
          marketerInstruction: "",
          marketerInstructions: [],
          commissionPolicy: "",
          commissionPolicies: [],
        });
        setFile(null);
        setImagePreview(null);
      }
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Something went wrong, try again!"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="px-6 bg-slate-100">
      <div className="flex items-center justify-between w-full">
        <h2 className="capitalize font-semibold text-xl">Add Product</h2>
        <button
          className="bg-black uppercase rounded-sm text-white flex gap-1 py-2 px-4 items-center justify-center"
          onClick={() => navigate(-1)}
        >
          <GoArrowLeft />
          <span className="font-semibold text-sm">Back</span>
        </button>
      </div>

      <form
        className="w-1/2 mx-auto bg-white p-4 my-8 rounded-md shadow"
        onSubmit={addProduct}
      >
        <h1 className="text-xl font-semibold border-b py-4 capitalize">
          Product Information
        </h1>

        <label className="block text-sm capitalize font-semibold my-2">
          Product Name*
        </label>
        <input
          type="text"
          name="name"
          value={form.name}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product name"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Description
        </label>
        <textarea
          name="description"
          value={form.description}
          onChange={handleChange}
          rows={4}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product description"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Price*
        </label>
        <input
          type="number"
          name="price"
          value={form.price}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Price"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Commission without Review*
        </label>
        <input
          type="number"
          name="comission"
          value={form.comission}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Commission with Text Review*
        </label>
        <input
          type="number"
          name="comissionWithTextReview"
          value={form.comissionWithTextReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
        <label className="block text-sm capitalize font-semibold my-2">
          Commission with Image Review*
        </label>
        <input
          type="number"
          name="comissionWithPictureReview"
          value={form.comissionWithPictureReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
        <label className="block text-sm capitalize font-semibold my-2">
          Commission with video Review*
        </label>
        <input
          type="number"
          name="comissionWithVideoReview"
          value={form.comissionWithVideoReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />

<label className="block text-sm capitalize font-semibold my-2">
          Review Guidelines
        </label>
        <textarea
          name="guideline"
          value={form.guideline}
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e, "guideline", "guidelines")}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter a guideline and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.guidelines.map((guideline, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-blue-100 border rounded-md"
            >
              <span>{guideline}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("guidelines", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        {/* Marketer Instructions */}
        <label className="block text-sm capitalize font-semibold my-2">
          Instructions for Marketer
        </label>
        <textarea
          name="marketerInstruction"
          value={form.marketerInstruction}
          onChange={handleChange}
          onKeyDown={(e) =>
            handleKeyDown(e, "marketerInstruction", "marketerInstructions")
          }
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter an instruction and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.marketerInstructions.map((instruction, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-green-100 border rounded-md"
            >
              <span>{instruction}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("marketerInstructions", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        {/* Commission Policies */}
        <label className="block text-sm capitalize font-semibold my-2">
          Commission Policies
        </label>
        <textarea
          name="commissionPolicy"
          value={form.commissionPolicy}
          onChange={handleChange}
          onKeyDown={(e) =>
            handleKeyDown(e, "commissionPolicy", "commissionPolicies")
          }
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter a policy and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.commissionPolicies.map((policy, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-yellow-100 border rounded-md"
            >
              <span>{policy}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("commissionPolicies", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        <label className="block text-sm capitalize font-semibold my-2">
          Select Thumbnail Image: *
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          ref={thumbnailImg}
          hidden
        />
        <label
          className="flex items-center justify-start border-blue-200 border w-max p-3 text-base transition-all gap-3 hover:bg-blue-200 hover:shadow-2xl capitalize font-semibold my-2 cursor-pointer"
          onClick={() => thumbnailImg.current.click()}
        >
          <FiUploadCloud />
          <span>Select Image</span>
        </label>
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            className="mt-2 h-32 object-cover rounded-md"
          />
        )}

        <button
          type="submit"
          className="w-full bg-black disabled:opacity-50 text-white py-2 rounded-md uppercase mt-4"
          disabled={loading}
        >
          {loading ? "Creating..." : "Create Product"}
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
