import React, { useState, useRef, useEffect } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { URI } from '../URL';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../context/firebase';

function OrderDetail() {
  const thumbnailImg = useRef();
const [reviewPrice,setReviewPrice]=useState(null)
const [orderS,setOrderS]=useState(null)
const [age, setAge] = useState('');
const [file,setFile]=useState(null)
const [errors, setErrors] = useState({});

const {id}=useParams()
const showScreenshot=(selectedFile)=>{

  setOrderS(URL.createObjectURL(selectedFile));
}
useEffect(()=>{
  if(file){

    showScreenshot(file)
  }
},[file])
  
const getSingleProduct = async (id) => {
  const token = localStorage.getItem("token");

  try {

    const response = await axios.get(
      `${URI}/api/products/${id}/singleproduct`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      const data = response.data.product;
      setReviewPrice({comissionWithTextReview:data?.comissionWithTextReview,comission:data?.comission})


    }
  } catch (error) {
    console.log(error);

  }
};

useEffect(()=>{
  getSingleProduct(id)
},[id])
// State to store form values
  const [formData, setFormData] = useState({
    reviewType: '',
    amzOrderNumber: '',
    buyerPayPal: '',
    buyerName: '',
    orderScreenshot: null,
    comments: '',
  });

  const handleChange2 = (event) => {
    setFormData({ ...formData, reviewType: event.target.value });
    setErrors({ ...errors, reviewType: '' });
  };


  // Handle change for inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input change for screenshot

  const handleOrderNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
  
    // Format: After 3 digits, add a hyphen, then after 10 digits, add another hyphen
    if (value.length > 3 && value.length <= 10) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length > 10 && value.length <= 19) {
      value = `${value.slice(0, 3)}-${value.slice(3, 10)}-${value.slice(10, 19)}`;
    }
  
    // Ensure the total length does not exceed 17 characters
    if (value.length > 19) {
      value = value.slice(0, 19);
    }
  
    setFormData({ ...formData, amzOrderNumber: value });
  };
  
  
  

  // Validate form inputs
  const validate = () => {
    let tempErrors = {};
    if (!formData.reviewType) tempErrors.reviewType = 'Review Type is required';
    if (!formData.amzOrderNumber) tempErrors.amzOrderNumber = 'AMZ Order Number is required';
    else if (!/^\d{3}-\d{7}-\d{7}$/.test(formData.amzOrderNumber)) tempErrors.amzOrderNumber = 'Invalid AMZ Order Number format';
    if (!formData.buyerPayPal) tempErrors.buyerPayPal = 'Buyer PayPal is required';
    if (!formData.buyerName) tempErrors.buyerName = 'Buyer Name is required';
    if (!file) tempErrors.file = 'order screenshot is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  
  const handleSubmit =async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (validate()) {

      // setFormData({...formData,})
    try {
      const mainref = ref(storage, `imges/${file.name + Date.now()}`);
      const uploadTask = await uploadBytes(mainref, file);
      const downloadUrl = await getDownloadURL(uploadTask.ref);

           const response = await axios.post(
        `${URI}/api/orders`,
        { productId: id, ...formData ,AgentStatus:"submitted",orderScreenshot:downloadUrl,comissionStatus:'unpaid',comission:formData.reviewType==="text"?reviewPrice.comissionWithTextReview:reviewPrice.comission},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = response.data;
      toast.success("Order is created successfully", {
        autoClose: 2000, // 2000 milliseconds = 2 seconds
      });
      setFormData({
        reviewType: '',
        amzOrderNumber: '',
        buyerPayPal: '',
        buyerName: '',
        orderScreenshot: null,
        comments: '',
      })


      
    } catch (error) {
      console.log(error)
    }
    }
  };

  return (
    <div className='w-10/12 mx-auto mt-5'>
      <p className='bg-blue-400 capitalize w-full p-2 text-white'>Submit Order</p>
      <form className='grid grid-cols-2 gap-4' onSubmit={handleSubmit}>
        
        {/* Review Type */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Review Type *
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="review-type-label">Review Type</InputLabel>
              <Select
                labelId="review-type-label"
                id="review-type-select"
                value={formData.reviewType}
                label="Review Type"
                onChange={handleChange2}
              >
                <MenuItem value="text">Text: Rs.{reviewPrice?.comissionWithTextReview}</MenuItem>
           
              </Select>
            </FormControl>
          </Box>
          {errors.reviewType && <p className="text-red-500 text-sm">{errors.reviewType}</p>}
        </div>
        
        {/* AMZ Order Number */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            AMZ Order Number *
          </label>
          <input
  type="text"
  name="amzOrderNumber"
  value={formData.amzOrderNumber}
  onChange={handleOrderNumberChange}
  className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
  placeholder="123-4567890-1234567"
  maxLength="19"
/>

          {errors.amzOrderNumber && <p className="text-red-500 text-sm">{errors.amzOrderNumber}</p>}
        </div>

        {/* Buyer PayPal */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Buyer PayPal *
          </label>
          <input
            type="text"
            name="buyerPayPal"
            value={formData.buyerPayPal}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Buyer PayPal"
          />
          {errors.buyerPayPal && <p className="text-red-500 text-sm">{errors.buyerPayPal}</p>}
        </div>

        {/* Buyer Name */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Buyer Name *
          </label>
          <input
            type="text"
            name="buyerName"
            value={formData.buyerName}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Buyer Name"
          />
          {errors.buyerName && <p className="text-red-500 text-sm">{errors.buyerName}</p>}
        </div>

        {/* Order Screenshot */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Order Screenshot
          </label>
          {
            orderS&&<img src={orderS} alt='order img'/>
          }
          <input
            type="file"
            ref={thumbnailImg}
            onChange={(e) => setFile(e.target.files[0])}
            hidden
          />
          <label
            className="flex items-center justify-start border-blue-200 border w-max p-3 text-base transition-all gap-3 hover:bg-blue-200 hover:shadow-2xl shadow-blue-100 capitalize font-semibold my-2 cursor-pointer"
            onClick={() => thumbnailImg.current.click()}
          >
            <FiUploadCloud />
            <span>Select Image</span>
          </label>
        </div>
        {errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}

        {/* Comments */}
        <div className='col-span-2'>
          <label className="block text-sm capitalize font-semibold my-2">
            Comments
          </label>
          <input
            type="text"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Comment"
          />
        </div>

        {/* Buttons */}
        <div>
          <button type="button" className='bg-slate-200 p-4 capitalize rounded-md'>
            Cancel
          </button>
          <button type="submit" className='bg-blue-300 p-4 ms-4 rounded-md'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default OrderDetail;
