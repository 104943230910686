/** @format */

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { URI } from "../URL";

function Orders() {
  const [orders, setOrders] = useState([]);
  const { user, formatTimestamp } = useContext(AuthContext);

  const orderStatuses = ["submitted", "verified", "reviewed", "completed"];

  const getOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/manager/orders`, {
        headers: {
          Authorization: token,
        },
      });
      setOrders(response.data);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };

  const handleStatusChange = async (event, orderId) => {
    const newStatus = event.target.value;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${URI}/api/orders/${orderId}`,
        { status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: newStatus } : order
          )
        );
      }
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className=" capitalize text-xl font-semibold">all orders</h1>
        <h1 className="first-letter:uppercase">
          you have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h1>
      </div>
      <div className="w-full my-5">
        <div className="flex items-center flex-col justify-between bg-white rounded-sm shadow-slate-300 shadow-md py-4 grow-[2]">
        <div className="flex gap-3 w-full overflow-x-auto flex-col px-2">
  {(user.role === "manager" || user.role === "admin") && orders?.length > 0 ? (
    orders.map((order) => (
      <div className="bg-white" key={order._id}>
        {/* Order Header */}
        <div className="flex items-center justify-between px-6 bg-blue-200 py-2">
          <p className="whitespace-nowrap">ORD# {order?._id}</p>
          <p>Last Update: {formatTimestamp(order?.updatedAt)}</p>
          
          {/* Conditional Select for Order Status */}
          {order?.AgentStatus === "cancelled" && (
            <select
              value={order?.status}
              disabled={order?.AgentStatus === "cancelled"}
              onChange={(e) => handleStatusChange(e, order._id)}
              className="block px-3 py-1 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value={order?.status} disabled>
                {order?.status}
              </option>
              {orderStatuses.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          )}

          {/* Cancelled Tag */}
          {order?.AgentStatus === "cancelled" && (
            <p className="bg-red-500 text-white p-1 rounded-sm">Cancelled</p>
          )}
        </div>

        {/* Order Details */}
        <div className="grid grid-cols-4 p-4 items-center bg-white">
          <div>
            <img
              src="https://www.lfjfreebies.org/store/images/US-186656_9cc98zuaxx_1728909422.jpg"
              alt="Order Image"
              className="h-32 w-auto"
            />
          </div>
          <div>
            <div className="font-medium">Marketplace: United States</div>
            <div className="font-medium">
              AMZ Order #: <span className="font-light text-sm">{order?.amzOrderNumber}</span>
            </div>
            <div className="font-medium">
              Buyer PP: <span className="font-light text-sm">{order?.buyerPayPal}</span>
            </div>
            <div className="font-medium">
              Buyer Name: <span className="font-light text-sm">{order?.buyerName}</span>
            </div>
          </div>
          <div>
            <div className="font-medium">
              Manager: <span className="font-light text-sm">{order?.agent?.name}</span>
            </div>
            <div className="font-medium">Commission Rating: Rs.300</div>
            <div className="font-medium">Screenshots: Order, Rating, Refund</div>
          </div>
     
        </div>
      </div>
    ))
  ) : (
    <div className="text-center font-semibold text-xl">No orders found</div>
  )}
</div>

        </div>
      </div>
    </div>
  );
}

export default Orders;
