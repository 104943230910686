/** @format */

import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { URI } from "../URL";
import axios from "axios";


const AccountSetting = () => {
  const { login } = useContext(AuthContext);
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const token = await localStorage.getItem('token'); // Get the token from localStorage
  
    // Ensure the token exists
    if (!token) {
      toast.error("Authentication token not found. Please log in again.");
      return;
    }
  
    // Get the form data (passwords)
    const { currentPassword, newPassword } = form;
  
    // Validate input fields
    if (!currentPassword || !newPassword) {
      toast.error("Please fill in both fields.");
      return;
    }
  
    const url = `${URI}/api/update/password`; 
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`, // Add the token to the Authorization header
    };
  
    const body = {
      currentPassword, // Current password
      newPassword,     // New password
    };
  
    try {
      const response = await axios.put(url, body, { headers });
  
      // Handle success response
      toast.success("Password updated successfully!",{
        delay:500
      });

    } catch (error) {
      // Handle error response
      if (error.response) {

        // Server responded with an error status
        toast.error(error.response.data.message || "Failed to update password.");
      } else if (error.request) {
        // No response received
        toast.error("No response received from server.",{
          delay:500
        });
      } else {
        // Error in setting up the request
        toast.error("Error setting up the request.",{
          delay:500
        });
      }
      console.error("Error:", error);
    }
  };
  

  const handleFormChanges = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const toggleShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <div>
      <div className="bg-slate-100 flex items-center justify-center min-h-screen">
        <form action="" className="bg-white p-8 w-1/3 rounded-sm">
          <label
            htmlFor="currentPassword"
            className="block text-sm capitalize font-semibold my-2"
          >
            Enter your current password*
          </label>
          <div className="relative">
            <input
              type={showPasswords.currentPassword ? "text" : "password"}
              name="currentPassword"
              onChange={handleFormChanges}
              value={form.currentPassword}
              className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
              placeholder="Current Password"
              id="currentPassword"
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500"
              onClick={() => toggleShowPassword("currentPassword")}
            >
              {showPasswords.currentPassword ?<FaEyeSlash/>: <FaEye/>}
            </button>
          </div>

          <label
            htmlFor="newPassword"
            className="block text-sm capitalize font-semibold my-2"
          >
            Enter your new password*
          </label>
          <div className="relative">
            <input
              type={showPasswords.newPassword ? "text" : "password"}
              name="newPassword"
              onChange={handleFormChanges}
              value={form.newPassword}
              className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
              placeholder="New Password"
              id="newPassword"
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500"
              onClick={() => toggleShowPassword("newPassword")}
            >
              {showPasswords.newPassword ?<FaEyeSlash/> : <FaEye/>}
            </button>
          </div>

          <button
            className="uppercase w-full bg-black py-2 text-white rounded-md mt-4"
            onClick={handleSubmit}
          >
            Update Password
          </button>

        
        </form>
      </div>
    </div>
  );
};

export default AccountSetting;
