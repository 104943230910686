// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpnZtnmuImAvLkz6AM1K9Dk6c0HypcKy8",
  authDomain: "files-18465.firebaseapp.com",
  projectId: "files-18465",
  storageBucket: "files-18465.firebasestorage.app",
  messagingSenderId: "41133531989",
  appId: "1:41133531989:web:4b5c1e7d80b108c238c5dc"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// const mainref = ref(storage, `imges/${i.name + Date.now()}`);
// const uploadTask = await uploadBytes(mainref, i);
// const downloadUrl = await getDownloadURL(uploadTask.ref);
// urls.push(downloadUrl);