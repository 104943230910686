/** @format */

import axios from "axios";
import { URI } from "../URL";
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { CiImageOn } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import Sidebar from "./Siderbar";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router";
function AgentOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, formatTimestamp } = useContext(AuthContext);
  const navigate=useNavigate()

const [popUpImage ,setPopUpImage]=useState(null)
 
const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange =async (event,orderId) => {
  
    const newStatus = event.target.value;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${URI}/api/orders/agent/${orderId}`,
        { AgentStatus: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: newStatus ,AgentStatus:newStatus} : order
          )
        );
      }
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };
  const getOrders = async (id) => {

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/agents/${id}/orders`, {
        headers: {
          Authorization: token,
        },
      });

      setOrders(response.data);
      setLoading(false);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };


  useEffect(() => {
    getOrders(user.id);
  }, []);
  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className=" capitalize text-xl font-semibold  ">all orders</h1>
        <h1 className="first-letter:uppercase">
          you have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h1>
      </div>
      <div className="w-full my-5">
        <div className="flex items-center flex-col justify-between  rounded-sm shadow-slate-300 shadow-md  py-4 grow-[2]">
 
          <div className="flex  w-full overflow-x-auto flex-col px-2 gap-4">
      
            {loading ? (
              <div className="text-center font-semibold text-2xl capitalize ">
                loading...
              </div>
            ) : orders && orders.length > 0 ? (
              orders.map((order,i) => (
               <div className="bg-white" key={i}>
               <div className="flex items-center justify-between px-6 bg-blue-200 py-2">
                <p className="text-nowrap ">
                  ORD# {order?._id}
                </p>
                <p>Last Update: {formatTimestamp(order?.updatedAt)}</p>
                {
                  order?.AgentStatus==="submitted"&&  <select
                  value={order?.status}
                  onChange={(e)=>handleChange(e,order._id)}
                  className="block  px-3 py-1 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value={order?.status} disabled>
                 {order?.status}
                  </option>
                  <option value="cancelled">Cancel</option>
                </select>
                }
                {
                   order?.AgentStatus==="cancelled"&& <p className="bg-red-500 text-white p-1 rounded-sm">cancelled</p>
                }
                    
                    <div className="relative">
                    <button
                    disabled={order?.AgentStatus==="cancelled"}
                      onClick={() =>
                        setSelectedOrderId(
                          selectedOrderId === order._id ? null : order._id
                        )
                      }
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <HiDotsVertical />
                    </button>
                    {selectedOrderId === order._id && (
                      <div className="absolute top-5 right-0 bg-white border border-gray-300 rounded-md shadow-md flex flex-col">
                     
                        <button
                          className="text-black px-4 py-2 hover:bg-gray-100 text-nowrap capitalize"
                          onClick={() =>
                            // handleUpdateOrder(order._id, "cancelled")
                            navigate(`/agent/update/order/${order._id}`)
                          }
                        >
update Order
                        </button>
                      </div>
                    )}
                  </div>
             
               </div>
                <div className="grid grid-cols-4 p-4 items-center bg-white">
                  <div><img src={order?.product?.image||"https://www.lfjfreebies.org/store/images/US-186656_9cc98zuaxx_1728909422.jpg"} alt="image-" className="h-32 w-auto" /></div>
                  <div>
                  



                  <div className="font-medium">Marketplace:  United States</div>
                  <div className="font-medium">AMZ Order #:  <span className="font-light text-sm">{order?.amzOrderNumber}</span></div>
                  <div className="font-medium">Buyer PP:  <span className="font-light text-sm">{order?.buyerPayPal}</span></div>
                  <div className="font-medium">Buyer Name : <span className="font-light text-sm">{order?.buyerName}</span> </div>
                  </div >
                  <div >
                  <div className="font-medium">Manager:  <span className="font-light text-sm">{order?.product?.vendor?.name}</span></div>
                  <div className="font-medium">Commission RATING: Rs.300</div>
                  <div className="font-medium">MPIN#: US-190199 <Sidebar id={order?.product?._id}/></div>
                  {/* <div className="font-medium">MPIN#: US-186656 (View Details)</div> */}
                  <div className="font-medium flex items-center justify-start gap-2">Screenshots:  
                  {
                    
                   order?.orderScreenshot&& <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.orderScreenshot)}>  <CiImageOn /> <span> order</span></button>
            
                  }
                  {
                    order?.refundShot&&
   <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.refundShot)}>  <CiImageOn /> <span> refund</span></button>

                  }
                        </div>
                  {
                    popUpImage&&     <div>
                    <div className="fixed top-0 left-0 w-full h-full bg-[#00000057]">
                    </div>
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[70vh]">
                      <button className="absolute -top-2 -right-2 rounded-full p-2 bg-white text-black" onClick={()=>setPopUpImage(null)}>
                      <RxCross2 />
                      </button>
                 
    
                    <img src={popUpImage} className=" h-full w-auto " alt="new "/>
                      </div>
                    
                
                    </div>
  
                  }
              
                  </div>
                  <div className="w-64 mx-auto mt-5">
 
   
   
    </div>
                </div>
                </div>
                
         
              ))
            ) : (
              <>
              <div className="text-center capitalize font-medium mt-4 ">
                no order found
              </div>
      
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentOrders;
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));