import axios from "axios";
import { URI } from "../URL";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router";

function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, formatTimestamp } = useContext(AuthContext);

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const navigate=useNavigate()

  const handleUpdateOrder = async (orderId, newStatus) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${URI}/api/orders/agent/${orderId}`,
        { AgentStatus: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId
              ? { ...order, AgentStatus: newStatus }
              : order
          )
        );
        setSelectedOrderId(null); // Close the pop-up after updating
      }
    } catch (err) {
      console.error("Error updating order:", err.response?.data || err.message);
    }
  };

  const getOrders = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/orders/all`, {
        headers: {
          Authorization: token,
        },
      });

      setOrders(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching orders:", err.response?.data || err.message);
    }
  };

  useEffect(() => {
    getOrders(user.id);
  }, [user.id]);

  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className="capitalize text-xl font-semibold">All Orders</h1>
        <h2 className="first-letter:uppercase">
          You have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h2>
      </div>
      <div className="w-full my-5">
        <div className="flex flex-col items-center rounded-sm shadow-md bg-white py-4">
          {loading ? (
            <div className="text-center font-semibold text-2xl capitalize">
              Loading...
            </div>
          ) : orders.length > 0 ? (
            orders.map((order, i) => (
              <div className="w-full border-b last:border-0" key={i}>
                <div className="flex items-center justify-between px-6 py-2 bg-blue-200">
                  <p>ORD# {order?._id}</p>
                  <p>Last Update: {formatTimestamp(order?.updatedAt)}</p>
                  {
                    order?.AgentStatus==="cancelled"&& <p className="bg-red-500 text-white px-2 py-1">
                    order cancelled
                    </p>
                  }
                 
                  <div className="relative">
                    <button
                    disabled={order?.AgentStatus==="cancelled"}
                      onClick={() =>
                        setSelectedOrderId(
                          selectedOrderId === order._id ? null : order._id
                        )
                      }
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <HiDotsVertical />
                    </button>
                    {selectedOrderId === order._id && (
                      <div className="absolute top-5 right-0 bg-white border border-gray-300 rounded-md shadow-md flex flex-col">
                     
                        <button
                          className="text-black px-4 py-2 hover:bg-gray-100 text-nowrap capitalize"
                          onClick={() =>
                            // handleUpdateOrder(order._id, "cancelled")
                            navigate(`/manager/update/order/${order._id}`)
                          }
                        >
update Order
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-4 p-4 items-center bg-white">
                  <div>
                    <img
                      src={order?.product?.image}
                      alt="product img"
                      className="h-32 w-auto"
                    />
                  </div>
                  <div>
                    <div className="font-medium">Marketplace: United States</div>
                    <div className="font-medium">
                      AMZ Order #:{" "}
                      <span className="font-light text-sm">
                        {order?.amzOrderNumber}
                      </span>
                    </div>
                    <div className="font-medium">
                      Buyer PP:{" "}
                      <span className="font-light text-sm">
                        {order?.buyerPayPal}
                      </span>
                    </div>
                    <div className="font-medium">
                      Buyer Name:{" "}
                      <span className="font-light text-sm">{order?.buyerName}</span>
                    </div>
                  </div>
                  <div>
                    <div className="font-medium">
                      Manager:{" "}
                      <span className="font-light text-sm">
                        {order?.product?.vendor?.name}
                      </span>
                    </div>
                    <div className="font-medium">
                      Commission RATING: Rs.300
                    </div>
                    <div className="font-medium">
                      Screenshots: Order Rating Refund
                    </div>
                  </div>
                  <div className="w-64 mx-auto mt-5"></div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center capitalize font-medium mt-4">
              No order found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminOrders;
