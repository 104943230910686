/** @format */

import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    login(form.email, form.password);
  };

  const handleFormChanges = function (e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const notify = () => toast("Wow so easy!");
  return (
    <div>
      <div className="bg-slate-100 flex items-center justify-center min-h-screen">
        <form action="" className="bg-white p-8 w-1/3 rounded-sm ">
          <h1 className="capitalize text-lg font-semibold border-b pb-2">
            login
          </h1>

          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            Email*
          </label>
          <input
            type="email"
            name="email"
            onChange={handleFormChanges}
            value={form.email}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="email"
            id=""
          />
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            password*
          </label>
          <input
            type="password"
            name="password"
            onChange={handleFormChanges}
            value={form.password}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="password"
            id=""
          />

          <button
            className="uppercase w-full bg-black py-2 text-white rounded-md mt-4"
            onClick={handleSubmit}
          >
            sign in
          </button>
          <p className="first-letter:uppercase mt-4 ">
            have no account?
            <Link
              to={"/register"}
              className="first-letter:uppercase text-blue-600 capitalize ms-2"
            >
              register
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
